import React from "react";

export default function Services() {
  return (
    <main>
      <section className="services">
        <div className="content">
          {/* <h1 className="underline">Service</h1> */}

          <div className="service-card">
            <h1>SITE PLAN</h1>

            <div className="benefits">
              <div className="benefit">
                <i class="fa-solid fa-circle-check"></i>
                <p>
                  100% Complete, SEO-Optimized Contractor Website With Client
                  Booking Integration
                </p>
              </div>
              <div className="benefit">
                <i class="fa-solid fa-circle-check"></i>
                <p>Everything Handled For You (Hosting, Domain, Upkeep)</p>
              </div>
              <div className="benefit">
                <i class="fa-solid fa-circle-check"></i>
                <p>Unlimited Changes, Anytime</p>
              </div>
              <div className="benefit">
                <i class="fa-solid fa-circle-check"></i>
                <p>24/7 Customer Service</p>
              </div>
              <div className="benefit">
                <i class="fa-solid fa-circle-check"></i>
                <p>Complementary Logo & GMB Profile Optimization</p>
              </div>
            </div>
            <p className="price">$125/mo</p>
          </div>

          <a href="/contact" className="hoverbtn">
            Get In Touch
          </a>
        </div>
      </section>
    </main>
  );
}

//hand coded
//contact page with phone number and contact form
